@function calculate-rem($size, $html-font-size) {
	$rem-size: $size / $html-font-size;

	@return #{$rem-size}rem;
}

@function calculate-line-height($line-height, $font-size) {
	$line-height: $line-height / $font-size;

	@return #{$line-height}em;
}

@function calculate-letter-spacing($spacing) {
	$letter-spacing: $spacing / 1000;

	@return #{$letter-spacing}em;
}

@function calculate-text-indent($spacing) {
	$text-indent: ($spacing / 1000) / 2;

	@return #{$text-indent}em;
}
