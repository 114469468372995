@import "framework/base.scss";
@import "framework/functions.scss";
@import "framework/grid.scss";
@import "framework/common.scss";

$html-font-size: 16px;
$color-pink: #ee3d78;
$color-yellow: #fee14b;
$color-gray: #fafafa;
$color-red-new: #ef2d17;
$color-red-1: $color-red-new;
$color-red-2: $color-red-new;

html {
	background: #fff;
	color: #000;
	font-family: "Montserrat", sans-serif;
	font-size: $html-font-size;
	font-weight: 300;
	line-height: 1em;

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 12px;
	}
}

a {
	color: #000;

	&:hover,
	&:focus {
		color: #000;
	}
}

/* Header */
header {
	background: #000;
	position: relative;

	> nav {
		padding: 30px 0;
		position: absolute;
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
		width: 450px;

		@include phone-down {
			position: static;
			transform: none;
			width: 100%;
		}

		> .container {

			@include phone-sm-down {
				display: block;
				text-align: center;
			}
		}

		.logo {
			display: inline-block;
			flex: 0 0 auto;
			vertical-align: middle;
		}

		ul {
			flex: 1 1 auto;
			list-style: none;
			margin: 0;
			padding: 40px 0 0 0;

			@include phone-sm-down {
				padding: 15px 0;
			}

			> li {
				display: inline-block;
				font-family: "Oswald", sans-serif;
				font-size: calculate-rem(20px, $html-font-size);
				font-weight: 700;
				line-height: 1em;
				text-transform: uppercase;
				vertical-align: middle;

				& + li {
					margin-left: 17px;
				}

				> a {
					color: #fff;

					@include hover-focus {
						color: $color-red-new;
					}
				}
			}
		}
	}

	> img {
		margin: 0 auto;
	}
}

/* Main */
main {

	.content {
		margin: 0 auto;
		max-width: 830px;
		position: relative;
		width: 100%;
	}

	.arrows {
		bottom: 0;
		margin: 0 0 0 auto;
		position: absolute;

		&.left {
			left: 0;
		}

		&.right {
			right: 0;
		}

		> ul {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			list-style: none;
			margin: 0;
			padding: 0;

			> li {
				flex: 1 1 auto;
				font-size: calculate-rem(22px, $html-font-size);
				line-height: 1em;
				text-align: center;

				> a {
					color: #fff;
					display: block;
					padding: 15px 40px;
				}

				&:nth-child(1) {

					> a {
						background: #fff;
						color: #000;

						@include hover-focus {
							background: #000;
							color: #fff;
						}
					}
				}

				&:nth-child(2) {

					> a {
						background: #000;

						@include hover-focus {
							background: #fff;
							color: #000;
						}
					}
				}
			}
		}
	}

	.section-header {
		align-content: flex-start;
		align-items: flex-end;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		margin-bottom: 50px;

		@include tablet-down {
			display: block;
			margin-bottom: 30px;
			text-align: center;
		}

		.section-title {
			color: #000;
			flex: 0 0 auto;
			font-family: "Oswald", sans-serif;
			font-size: calculate-rem(100px, $html-font-size);
			font-weight: 700;
			line-height: 1em;
			margin: 0 auto;
			text-transform: uppercase;
		}

		.section-menu {
			flex: 1 1 auto;
			list-style: none;
			margin: 0 0 0 17px;
			padding: 0;
			text-align: left;

			@include tablet-down {
				margin: 15px 0 0 0;
				text-align: center;
			}

			> li {
				display: inline-block;
				font-family: "Oswald", sans-serif;
				font-size: calculate-rem(34px, $html-font-size);
				font-weight: 700;
				line-height: 1em;
				text-transform: uppercase;
				vertical-align: middle;

				& + li {
					margin-left: 17px;
				}

				> a {

					.active,
					&:hover,
					&:focus {
						color: $color-pink;
					}
				}
			}
		}
	}

	#bio {
		background-color: #fafafc;

		@include tablet-down {
			text-align: center;
		}

		.container {
			max-width: 100%;
			padding: 0;
		}

		.content {
			max-width: 100%;
		}

		.section-header {
			display: block;
		}

		.section-title {
			text-align: left;

			@include tablet-down {
				text-align: center;
			}
		}

		.section-content {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			margin: 0 auto;
			width: 100%;

			@include tablet-down {
				display: block;
			}

			> div {

				&:first-child {
					flex: 0 0 50%;

					> img {
						height: 100%;
						object-fit: cover;
						object-position: center top;
						width: 100%;

						@include tablet-down {
							height: auto;
						}
					}
				}

				&:last-child {
					flex: 0 0 50%;
					padding: 60px;

					.inner-bio {
						height: 500px;
						overflow-y: auto;

						@include tablet-down {
							height: auto;
						}
					}

					p {
						font-size: 1rem;
						line-height: calculate-line-height(28px, 20px);
						margin: 0 0 20px 0;

						&:last-child {
							margin: 0;
						}
					}
				}
			}
		}
	}

	#music {
		background: #000 url('../img/music.png') no-repeat top right;
		background-size: auto 100%;

		@include tablet-down {
			background: #000;
		}

		.content {
			padding: 110px 0 150px 0;

			@include tablet-down {
				padding: 30px 0 60px 0;
			}
		}

		.section-header {
			display: block;
			margin-bottom: 110px;

			@include tablet-down {
				margin-bottom: 30px;
			}

			.section-title {
				color: #fff;
				text-align: left;

				@include tablet-down {
					text-align: center;
				}
			}
		}

		.section-content {
			max-width: 400px;
			width: 100%;

			@include tablet-down {
				margin: 0 auto;
			}

			> div {

				&:not(.active) {
					display: none;
				}

				iframe {
					display: block;
					width: 100%;
				}
			}
		}
	}

	#video {
		background: $color-red-new;

		.content {
			padding: 100px 0 100px 0;

			@include tablet-down {
				padding: 30px 0 60px 0;
			}
		}

		.section-header {
			display: block;

			.section-title {
				color: #fff;
				text-align: center;
			}
		}

		.section-content {

			.videos {
				margin-top: 40px;

				@include tablet-down {
					margin-top: 30px;
				}

				.item {

					> a {
						display: block;
						opacity: 1;

						@include hover-focus {
							opacity: 0.8;
						}

						> img {
							width: 100%;
						}
					}
				}
			}
		}
	}

	#tour {
		background: $color-gray;
		background-image: url("../img/tour.png");
		background-position: right bottom;
		background-repeat: no-repeat;
		background-size: contain;

		@media screen and (max-width: 1350px) {
			background-image: none;
		}

		.content {
			padding: 100px 0 100px 0;

			@include tablet-down {
				padding: 30px 0 60px 0;
			}
		}

		.section-header {
			display: block;

			.section-title {
				color: $color-red-new;
			}
		}

		.section-content {
			margin: 0 auto;
			max-width: 730px;
			width: 100%;
		}

		#events {

			.tour-page {

				&:not(.active) {
					display: none;
				}
			}

			.no-events {
				font-weight: 800;
				padding: 15px;
				text-align: center;
			}

			.tour-date {
				border-left: 3px solid transparent;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				margin: 0 -15px;
				padding: 30px;

				@include tablet-down {
					display: block;
					text-align: center;
				}

				@include hover-focus {
					background: #fff;
					border-left: 3px solid $color-yellow;
				}

				> div {
					padding: 0 15px;

					@include tablet-down {
						max-width: 100% !important;
					}
				}

				.tour-date-time {
					display: flex;
					flex: 0 1 20%;
					max-width: 20%;

					@include tablet-down {
						display: block;
					}

					> div {
						flex: 0 1 50%;

						&:nth-child(1) {
							color: #9e9fa9;
							font-size: calculate-rem(40px, $html-font-size);
							font-weight: 800;
							line-height: 1em;
						}

						&:nth-child(2) {
							padding-left: 15px;

							@include tablet-down {
								margin-top: 5px;
								padding-left: 0;
							}

							> span {
								display: block;

								&:nth-child(1) {
									font-size: calculate-rem(14px, $html-font-size);
									font-weight: 600;
									line-height: 1em;
								}

								&:nth-child(2) {
									color: #c7c7c7;
									font-size: calculate-rem(12px, $html-font-size);
									font-weight: 500;
									line-height: 1em;
									margin-top: 5px;
								}
							}
						}
					}
				}

				.tour-location {
					flex: 0 1 55%;
					max-width: 55%;

					@include tablet-down {
						padding: 15px 0;
					}

					> span {
						display: block;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						&:nth-child(1) {
							font-size: calculate-rem(14px, $html-font-size);
							font-weight: 600;
							line-height: 1em;
						}

						&:nth-child(2) {
							color: #c7c7c7;
							font-size: calculate-rem(12px, $html-font-size);
							font-weight: 500;
							line-height: 1em;
							margin-top: 5px;
						}
					}
				}

				.tour-tickets {
					flex: 0 1 25%;
					max-width: 25%;
					text-align: right;

					@include tablet-down {
						text-align: center;
					}

					a {
						border: 2px solid #c7c7c7;
						color: #c7c7c7;
						display: inline-block;
						font-size: calculate-rem(14px, $html-font-size);
						font-weight: 600;
						line-height: 1em;
						padding: 12px 24px;
						text-align: center;
						vertical-align: middle;

						@include hover-focus {
							background: $color-red-1;
							border-color: $color-red-1;
							color: #fff;
						}
					}

					span {
						background: #ededed;
						border: 2px solid #ededed;
						color: #c7c7c7;
						display: inline-block;
						font-size: calculate-rem(14px, $html-font-size);
						font-weight: 600;
						line-height: 1em;
						padding: 12px 24px;
						text-align: center;
						vertical-align: middle;
					}
				}
			}
		}
	}
}

/* Footer */
footer {
	background: #000;
	color: #9e9fa9;
	font-size: calculate-rem(12px, $html-font-size);
	line-height: 1em;
	padding: 50px 0;

	@include tablet-down {
		padding: 30px 0;
	}

	.copyright {
		line-height: 1.3em;
		text-align: center;

		p {
			margin: 0;
		}

		a {
			color: #9e9fa9;

			@include hover-focus {
				color: #fff;
			}
		}
	}
}
