/* Helpers */
.text-center {
	text-align: center;
}

/* Lists */
.inline-list {
	margin: 0;
	padding: 0;
	list-style: none;

	> li {
		display: inline-block;
		vertical-align: middle;
	}
}

/* Form */
.input-wrap {
	margin: 0 0 10px 0;
}

.input {
	display: block;
	width: 100%;
	padding: 5px;
	border: 0;
	border-radius: 0;
	font-family: inherit;
	font-weight: inherit;
	-webkit-transition: color 250ms linear, background-color 250ms linear, border-color 250ms linear, opacity 250ms linear;
	transition: color 250ms linear, background-color 250ms linear, border-color 250ms linear, opacity 250ms linear;

	&:focus {
		outline: 0;
	}
}

.submit {
	border-style: solid;
	font-family: inherit;
	font-weight: inherit;
	text-decoration: none;
	-webkit-transition: color 250ms linear, background-color 250ms linear, border-color 250ms linear, opacity 250ms linear;
	transition: color 250ms linear, background-color 250ms linear, border-color 250ms linear, opacity 250ms linear;

	&:hover,
	&:focus {
		outline: 0;
		cursor: pointer;
	}
}
