$container-max-width: 1230px;
$gutter-size: 15px;
$break-point-desktop-md-max: 1200px;
$break-point-desktop-sm-max: 1199px;
$break-point-desktop-sm-min: 992px;
$break-point-tablet-max: 991px;
$break-point-tablet-min: 768px;
$break-point-phone-max: 767px;
$break-point-phone-sm: 480px;

@mixin desktop-sm-down {

	@media screen and (max-width: $break-point-desktop-sm-max) {

		@content;
	}
}

@mixin tablet-down {

	@media screen and (max-width: $break-point-tablet-max) {

		@content;
	}
}

@mixin phone-down {

	@media screen and (max-width: $break-point-phone-max) {

		@content;
	}
}

@mixin phone-sm-down {

	@media screen and (max-width: $break-point-phone-sm) {

		@content;
	}
}

.container {
	width: 100%;
	max-width: $container-max-width;
	margin: 0 auto;
	padding: 0 $gutter-size;
}

.row {
	margin: 0 (-$gutter-size);

	&:after {
		display: table;
		clear: both;
		content: " ";
	}
}

.col {
	float: left;
	position: relative;
	min-height: 1px;
	padding: 0 $gutter-size;

	&.col-20 {
		width: 20%;
	}

	&.col-25 {
		width: 25%;
	}

	&.col-33 {
		width: 33.333333%;
	}

	&.col-35 {
		width: 35%;
	}

	&.col-40 {
		width: 40%;
	}

	&.col-45 {
		width: 45%;
	}

	&.col-50 {
		width: 50%;
	}

	&.col-55 {
		width: 55%;
	}

	&.col-60 {
		width: 60%;
	}

	@media screen and (max-width: $break-point-tablet-max) {
		float: none;
		width: 100% !important;
	}
}

.flex-row {
	display: flex;
	justify-content: center;
	align-items: stretch;
	flex-wrap: nowrap;
	flex-direction: row;
	margin: 0 (-$gutter-size);

	&.align-items-center {
		align-items: center;
	}

	@media screen and (max-width: $break-point-tablet-max) {
		flex-wrap: wrap;
	}
}

.flex-col {
	flex-grow: 1;
	flex-shrink: 1;
	padding: 0 $gutter-size;

	&.col-5 {
		flex-basis: 5%;
		max-width: 5%;
	}

	&.col-10 {
		flex-basis: 10%;
		max-width: 10%;
	}

	&.col-15 {
		flex-basis: 15%;
		max-width: 15%;
	}

	&.col-20 {
		flex-basis: 20%;
		max-width: 20%;
	}

	&.col-25 {
		flex-basis: 25%;
		max-width: 25%;
	}

	&.col-30 {
		flex-basis: 30%;
		max-width: 30%;
	}

	&.col-33 {
		flex-basis: 33.333333%;
		max-width: 33.333333%;
	}

	&.col-35 {
		flex-basis: 35%;
		max-width: 35%;
	}

	&.col-40 {
		flex-basis: 40%;
		max-width: 40%;
	}

	&.col-45 {
		flex-basis: 45%;
		max-width: 45%;
	}

	&.col-50 {
		flex-basis: 50%;
		max-width: 50%;
	}

	&.col-55 {
		flex-basis: 55%;
		max-width: 55%;
	}

	&.col-60 {
		flex-basis: 60%;
		max-width: 60%;
	}

	&.col-65 {
		flex-basis: 65%;
		max-width: 65%;
	}

	&.col-70 {
		flex-basis: 70%;
		max-width: 70%;
	}

	&.col-75 {
		flex-basis: 75%;
		max-width: 75%;
	}

	&.col-80 {
		flex-basis: 80%;
		max-width: 80%;
	}

	&.col-85 {
		flex-basis: 85%;
		max-width: 85%;
	}

	&.col-90 {
		flex-basis: 90%;
		max-width: 90%;
	}

	&.col-95 {
		flex-basis: 95%;
		max-width: 95%;
	}

	&.col-100 {
		flex-basis: 100%;
		max-width: 100%;
	}

	@media screen and (max-width: $break-point-tablet-max) {
		flex-basis: 100% !important;
		max-width: 100% !important;
	}
}
